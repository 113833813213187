import React from "react"
import Layout from "../../components/layout"
import { graphql, Link } from "gatsby"
import { HouseCard } from "../../components/card"
import { formatPrice } from "../../utils/format"
import { HousesGoogleMap } from "../../components/map"
import { SelectCity } from "../../components/select"
import "../../styles/common/houses-list.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import CountLabel from "../../components/label/count-label"

const ARCHIVED_HOUSES_NUM = 8
const getActiveReadyHouses = data => {
  return data.houses.edges.filter(h => !h.node.archived && !h.node.building)
}
const getBuildingReadyHouses = data => {
  return data.houses.edges.filter(h => !h.node.archived && h.node.building)
}

const CityHousesPage = ({ data, location }) => {
  const city = data.city

  const liveReadyHouses = getActiveReadyHouses(data)
  const liveBuildingHouses = getBuildingReadyHouses(data)
  const liveHouses = [...liveReadyHouses, ...liveBuildingHouses]
  const archivedHouses = data.houses.edges.filter(h => h.node.archived)

  const minPrice = Math.min(...liveHouses.map(h => h.node.price).filter(p => p))
  const haveInfrastructures = city.infrastructures
    ? city.infrastructures.length > 0
    : false

  const title = "Купити новий, цегляний будинок " + city.name
  const description = `Від ${formatPrice(minPrice)} $. ${
    city.name
  }. Нові, якісні цегляні будинки. ${liveHouses.length}`

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      thumbnail={city.thumbnail && city.thumbnail.fluid.src}
      location={location}
    >
      <h1 className="city-houses-header">
        <SelectCity />
      </h1>
      {liveReadyHouses.length > 0 && (
        <React.Fragment>
          <div className="card-list-header">
            <h2>Готові будинки</h2> <CountLabel text={liveReadyHouses.length} />
          </div>
          <div className="card-list">
            {liveReadyHouses.map(house => (
              <HouseCard house={house.node} key={house.node.slug} />
            ))}
          </div>
        </React.Fragment>
      )}
      {liveBuildingHouses.length > 0 && (
        <React.Fragment>
          <div className="card-list-header">
            <h2>Скоро в продажі</h2>{" "}
            <CountLabel text={liveBuildingHouses.length} />
          </div>
          <div className="card-list">
            {liveBuildingHouses.map(house => (
              <HouseCard house={house.node} key={house.node.slug} />
            ))}
          </div>
        </React.Fragment>
      )}
      {archivedHouses.length > 0 && (
        <React.Fragment>
          <div className="card-list-header">
            <h2>Реалізовані будинки</h2>
          </div>
          <div className="card-list">
            {archivedHouses.slice(0, ARCHIVED_HOUSES_NUM).map(house => (
              <HouseCard
                house={house.node}
                key={house.node.slug}
                imgClass="grayscale-card"
              />
            ))}
          </div>
        </React.Fragment>
      )}
      <br />
      <h2>Карта</h2>
      <HousesGoogleMap
        houses={[...liveHouses, ...archivedHouses].map(h => h.node)}
        zoom={13}
      />
      <br />
      {haveInfrastructures ? (
        <p className="infrastructure-question">
          <Link to={`/${city.slug}/infrastructures`}>
            Яка інфраструктура знаходиться поряд?
          </Link>
        </p>
      ) : null}
      {city.description ? (
        <div className="description-text">
          {documentToReactComponents(city.description.json)}
        </div>
      ) : null}
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    city: contentfulCity(slug: { eq: $slug }) {
      slug
      name
      description {
        json
      }
      thumbnail {
        fluid(maxWidth: 1200, maxHeight: 900, cropFocus: CENTER) {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
      infrastructures {
        name
      }
    }
    houses: allContentfulHouse(
      filter: { node_locale: { eq: "uk-UA" }, city: { slug: { eq: $slug } } }
      sort: { fields: [archived, landArea], order: [ASC, DESC] }
    ) {
      edges {
        node {
          createdAt
          queue
          area
          slug
          code
          snippet
          roomsCount
          hotSale
          landArea
          price
          archived
          isFirst
          building
          bathrooms_number
          city {
            name
            slug
          }
          status {
            name
          }
          location {
            lon
            lat
          }
          thumbnail {
            fluid(maxWidth: 600) {
              sizes
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

export default CityHousesPage
