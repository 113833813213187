import React from "react"
import Select from "react-select"
import { navigate } from "gatsby"
import { useCitiesList } from "../../hooks"
import { getLastesUrlPathKey } from "../../utils"
import "./select-city.scss"

export default () => {
  const cities = useCitiesList()
  const options = [
    {
      label: "Всі будинки",
      value: "all-houses",
    },
    ...cities.map(city => ({
      value: city.slug,
      label: city.name,
    })),
  ]

  const pathKey = getLastesUrlPathKey()
  const value = options.find(op => op.value === pathKey)

  const onChange = op => {
    navigate(`/${op.value}`)
  }

  const styles = {
    control: base => ({
      ...base,
      border: 0,
      boxShadow: "none",
      padding: 0,
      margin: 0,
      lineHeight: 1,
      cursor: "pointer",
      maxWidth: `${100 + value?.label.length * 17}px`,
      width: "100%",
    }),
    menuList: base => ({
      ...base,
      fontSize: "16px",
      padding: 0,
    }),
    placeholder: base => ({
      ...base,
      color: "#222",
    }),
    input: base => ({
      ...base,
      color: "transparent",
    }),
    valueContainer: base => ({
      ...base,
      padding: 0,
    }),
  }

  return (
    <div className="select-wrapper">
      <Select
        styles={styles}
        placeholder="поселення"
        menuPlacement="auto"
        value={value}
        onChange={onChange}
        options={options}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  )
}
